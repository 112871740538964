import React from "react"
import Slider from "react-slick"
import responsive from "../slider-resonsive"
import "slick-carousel/slick/slick.css"
import "slick-carousel/slick/slick-theme.css"
import { GatsbyImage } from "gatsby-plugin-image"
import "../../css/custom-css/custom-products-services.css"

const ProductSlider = ({ products = [] }) => {
  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 3,
    slidesToScroll: 1,
    autoplay: false,
    responsive: [
      {
        breakpoint: 1200,
        settings: {
          slidesToShow: 2,
        },
      },
      {
        breakpoint: 600,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
          initialSlide: 1,
        },
      },
      // {
      //   breakpoint: 480,
      //   settings: {
      //     slidesToShow: 1,
      //     slidesToScroll: 1,
      //   },
      // },
    ],
  }
  return (
    <>
      <Slider {...settings} className="btn-style-1">
        {products.map(product => {
          const { id, title, images } = product
          return (
            <div
              className="item wow"
              data-wow-duration="2s"
              data-wow-delay="0.3s"
              key={id}
            >
              <div className="project-bx">
                <div className="media dlab-img-effect zoom">
                  <GatsbyImage image={images.gatsbyImageData}/>
                </div>
                <div className="info">
                  <h4 className="title product-title">{title}</h4>
                </div>
              </div>
            </div>
          )
        })}
      </Slider>
    </>
  )
}

export default ProductSlider
