import React from "react"
import { Link } from "gatsby"
import { StaticImage, getImage, GatsbyImage } from "gatsby-plugin-image"
import "../../css/custom-css/custom-products-services.css"
// import tile1 from "../../images/products-and-services/tiles-4.webp"
// import tile2 from "../../images/products-and-services/tiles-8.webp"
// import tile3 from "../../images/products-and-services/tiles-3.webp"
import tile1 from "../../images/products-and-services/services-1.jpg"
import tile2 from "../../images/products-and-services/services-4.jpg"
import tile3 from "../../images/products-and-services/services-3.jpg"

const ServicesTiles = () => {
  return (
    <div className="bg-white">
      <div className="content-block">
        <div className="section-full">
          <div className="row spno about-industry">
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile1} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title pb-2">Novel Diagnostics</h2>
                  <p className="service-tile-content">
                    One of our precision oncology test Mammaprint, was the first
                    FDA cleared IVDMIA breast cancer recurrence assay.
                  </p>
                  <Link
                    to="/products-and-services/services/novel-diagnostics"
                    className="site-button outline-2 btnhover11 mt-3"
                  >
                    See More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.2s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile3} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title pb-2">Therapeutics</h2>
                  <p className="service-tile-content">
                    We have various drugs in key therapeutics areas such as
                    biosimilars, novel cancer drugs and diagnostics, which are
                    commercialized in most ASEAN countries.
                  </p>
                  <Link
                    to="/products-and-services/services/therapeutics"
                    className="site-button outline-2 btnhover11 mt-3"
                  >
                    See More
                  </Link>
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.6s"
            >
              <div className="dlab-post-media dlab-img-effect zoom">
                <div className="img-cover">
                  <img src={tile2} className="tile-image" />
                </div>
              </div>
            </div>
            <div
              className="col-lg-4 bg-white text-black wow fadeIn"
              data-wow-duration="2s"
              data-wow-delay="0.8s"
            >
              <div className="service-box style2">
                <div>
                  <h2 className="title pb-2">Other Services</h2>
                  <p className="service-tile-content">
                    We welcome collaborations in clinical development with
                    companies which have strong science-based pipeline. We and
                    our sister companies also provide laboratory services such
                    as BA (Bioavailability) and BE (Bioequivalence) studies, CMO
                    for biologics, and diagnostic services including biomarker.
                  </p>
                  <Link
                    to="/products-and-services/services/other-services"
                    className="site-button outline-2 btnhover11 mt-3"
                  >
                    See More
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  )
}

export default ServicesTiles
