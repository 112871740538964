import React from "react"
import { graphql, useStaticQuery } from "gatsby"

import "../../css/custom-css/custom-products-services.css"

import Layout from "../../components/custom-components/Layout"
import ProductSlider from "../../components/custom-components/ProductSlider"
import BannerHeader from "../../components/custom-components/BannerHeader"
import ServiceTiles from "../../components/custom-components/ServicesTiles"
import tile1 from "../../images/products-and-services/tiles-4.webp"
import tile2 from "../../images/products-and-services/tiles-8.webp"
import tile3 from "../../images/products-and-services/tiles-3.webp"

// import bg from "../../images/products-and-services/header-products-and-services.webp"
import bg from "../../images/products-and-services/header-pns.jpg"
import Seo from "../../components/seo"

const query = graphql`
  {
    allContentfulProduct(sort: { order: ASC, fields: createdAt }) {
      nodes {
        id
        title
        images {
          gatsbyImageData(
            layout: CONSTRAINED
            placeholder: TRACED_SVG
            cropFocus: CENTER
            height: 834
            width: 1500
            resizingBehavior: FILL
          )
        }
      }
    }
  }
`

const ProductsAndServices = () => {
  const {
    allContentfulProduct: { nodes: products },
  } = useStaticQuery(query)

  return (
    <Layout>
      <Seo title="Products and Services" />

      <BannerHeader background={bg} title="Products and Services" />
      <div className="section-head text-black text-center container">
        <p className="head-content">
          At Innogene Kalbiotech we enrich diagnostics, therapeutics and health
          care by bringing in the latest breakthroughs in biology and
          biotechnology. Our products carry forward our mission to transforms
          patients’ health and lives, which we believe will work towards
          bettering life globally.
        </p>
      </div>
      <div className="page-content bg-gray frame-box">
        <div className="container">
          <div className="section-full content-inner-custom frame-project-area">
            <div className="section-head text-center style2">
              <h2 className="title">Our Products</h2>
            </div>
            <ProductSlider products={products} />
          </div>
        </div>
      </div>
      <ServiceTiles />
    </Layout>
  )
}

export default ProductsAndServices
